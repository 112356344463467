/* ChatUI.css */
.chat-container {
    height: 84vh;
    padding: 5%;
    display: flex;
    border-radius: 12px;
    flex-direction: column;
    max-height: 84vh;
    
    color: #F7F7F7;
    overflow: hidden; /* Hide any overflowing content */
}

.message-container {
    margin-top: 8%;
    background-color: #FFF;
    padding: 2%;
    flex: 1;
    overflow-y: auto;
    display: flex;
    flex-direction: column;
   
}

.message {
    padding: 10px;
    margin-top: 3%;
    margin-bottom: 3%;
   
}

.user-message {
    background-color: #393E46;
    color: #F7F7F7;
    border-radius: 5px 5px 5px 0;
    margin-right: auto; /* Move user messages to the left by aligning them to the right edge */
    align-self: flex-start; /* Align user messages to the left */
    text-align: left;
    width: 400px;
    border-radius: 20px;
}

.bot-message {
    background-color: #EEEEEE;
    color: #393E46;
    border-radius: 5px 5px 0 5px;
    margin-left: auto; /* Move bot messages to the right by aligning them to the left edge */
    align-self: flex-end; /* Align bot messages to the right */
    text-align: left;
    width: 400px;
    border-radius: 20px;
}

.input-container {
    padding: 3%;
    display: flex;
    align-items: center;
    margin-top: 20px; /* Adjust the margin between message container and input container */
}

.input-field {
    flex: 1;
    border-radius: 5px 0 0 5px;
}

.send-button {
    border-radius: 0 5px 5px 0;
}


/* Hide the scrollbar */
.message-container::-webkit-scrollbar {
    width: 0; /* Set the width of the scrollbar to 0 */
    background: transparent; /* Set the background of the scrollbar to transparent */
}


/* Custom scrollbar styles */
::-webkit-scrollbar {
    width: 10px;
  }
  
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  
  ::-webkit-scrollbar-thumb {
    background: #888;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }