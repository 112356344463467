body {
  margin: 0;
  font-family: 'Segoe UI', Arial, sans-serif;
  background-color: #161616;
    -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #fff;


}

@keyframes glimmer {
  0% {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.2);
  }
  50% {
    box-shadow: 0 0 20px 10px rgba(255, 255, 255, 0.6);
  }
  100% {
    box-shadow: 0 0 5px 2px rgba(255, 255, 255, 0.2);
  }
}

.container-glimmer-animation {
  animation: glimmer 2s infinite;
}

@keyframes borderAnimation {
  0% {
    border-color: #EEEEEE;
  }
  50% {
    border-color: #007bff;
  }
  100% {
    border-color: #EEEEEE;
  }
}

.container-border-animation {
  animation: borderAnimation 2s infinite;
}


.btn-primary {
  --bs-btn-color: #fff;
  --bs-btn-bg: #5234fa;
  --bs-btn-border-color: #5234fa;
  --bs-btn-hover-color: #fff;
  --bs-btn-hover-bg: #5234fa;
  --bs-btn-hover-border-color: #5234fa;
  --bs-btn-focus-shadow-rgb: 49, 132, 253;
  --bs-btn-active-color: #fff;
  --bs-btn-active-bg: #5234fa;
  --bs-btn-active-border-color: #5234fa;
  --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
  --bs-btn-disabled-color: #fff;
  --bs-btn-disabled-bg: #5234fa;
  --bs-btn-disabled-border-color: #5234fa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl, .container-xxl {
  --bs-gutter-x: 1.5rem;
}



.form-control::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #F7F7F7;
            opacity: 1; /* Firefox */
}

.form-control:-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #F7F7F7;
}

.form-control::-ms-input-placeholder { /* Microsoft Edge */
            color: #F7F7F7;
 }

 .auth-container {
  display: flex;
  border-radius: 12px;
  flex-direction: column;
  max-height: 75vh;
  color: #F7F7F7;
  overflow: hidden; /* Hide any overflowing content */
}

.navbar {
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.navbar-brand img {
  max-height: 40px;
}

.navbar-text img {
  max-height: 40px;
}